import React from 'react';

const Textarea = ({tag, resize = 'both', onChangeFun, ...props}) => {
    const resizeClass = {
        none: 'resize-none',
        x: 'resize-x',
        y: 'resize-y',
        both: 'resize',
    }[resize];

    return (
        <div>
            {tag ? tag : null}
        <textarea
            className={`bg-white border border-input text-sm p-1 rounded-md w-full min-h-32 ${resizeClass}`}
            placeholder="Pište..."
            {...props}
            onBlur={onChangeFun}
        >
        {props.text}

    </textarea></div>
    );
};

export default Textarea;
