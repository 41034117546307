import React, {useEffect, useState} from 'react';
import Textarea from '../controls/Textarea';
import Tag from "./Tag";

const SetRow = ({rows, setValue, value, currRes, lastRes, copilotRes}) => {
    const [res, setRes] = useState(copilotRes)
    const [ok, setOk] = useState("NONE")
    const [duplicate, setDuplicate] = useState(false)

    useEffect(() => {
        if (copilotRes?.includes("NOK")) {
            setOk("NOK")
            setRes(copilotRes?.replace("NOK:", ""))
        } else if (copilotRes?.includes("OK")) {
            setOk("OK")
            setRes(copilotRes?.replace("OK:", ""))
        } else {
            setOk("")
            setRes("")
        }

    }, [copilotRes])

    return (
        <>
            <div className='grid grid-col-1 col-span-2'>
                <Textarea resize="y" placeholder="Pište..." tag={duplicate ? <Tag type={"status"} status={"DUPLICATED"}/> : null} text={value} onChangeFun={(e) => {
                    if (rows.find(r => r === e.target.value)?.length > 1) {
                        setDuplicate(true)
                    }
                    else {
                        setDuplicate(false)
                        setValue(e.target.value)
                    }

                }}/>
            </div>
            <div className='grid grid-col-1 col-span-4 border-2 p-1'>
                <p>{currRes}</p>
            </div>
            <div className='grid grid-col-1 col-span-4 border-2 p-1'>
                <p>{lastRes}</p>
            </div>
            <div className={'grid grid-col-1 col-span-2 border-2 p-1'}>
                <div className='flex flex-col gap-2'>
                    <p className='text-sm'><Tag type={"status"} status={ok}/>{res}</p>
                </div>
            </div>
        </>
    );
};

export default SetRow;
