import React from 'react';
import PropTypes from 'prop-types';

const getColorFromValue = (value) => {
  const red = Math.round((1 - value) * 255);
  const green = Math.round(value * 255);
  const color = `rgb(${red}, ${green}, 0)`; 
  return color;
};

const getCzechWordForm = (value) => {
  if (value === 1) return 'slovo';
  if (value >= 2 && value <= 4) return 'slova';
  return 'slov';
};

const Tag = ({ status, type, value }) => {
  let content;
  let styleClasses;
  let customBackgroundColor;

  switch (type) {
    case 'status':
      content = status;
      styleClasses = {
        OK: 'bg-green-100 text-green-800',
        NOK: 'bg-yellow-100 text-yellow-800',
        ERR: 'bg-red-100 text-red-800',
        DUPLICATED: 'bg-red-100 text-red-800',
      }[status];
      break;
    case 'languageQuality':
      content = `Kval. jazyka ${value.toFixed(2)}`;
      customBackgroundColor = getColorFromValue(value);
      styleClasses = 'text-black';
      break;
    case 'responseQuality':
      content = `Kval. odpovědi ${value.toFixed(2)}`;
      customBackgroundColor = getColorFromValue(value);
      styleClasses = 'text-black';
      break;
    case 'wordCount':
      const wordLabel = getCzechWordForm(value);
      content = `${value} ${wordLabel}`;
      styleClasses = 'bg-white text-gray-800';
      break;
    default:
      content = '';
      styleClasses = 'bg-white text-gray-800';
  }

  return (
    <div
      className={`inline-flex items-start w-fit h-fit py-1 px-2 rounded-lg text-[10px] font-medium ${styleClasses}`}
      style={customBackgroundColor ? { backgroundColor: customBackgroundColor } : {}}
    >
      {content}
    </div>
  );
};

Tag.propTypes = {
  status: PropTypes.oneOf(['OK', 'NOK', 'ERR', 'DUPLICATED']),
  type: PropTypes.oneOf(['status', 'languageQuality', 'responseQuality', 'wordCount']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tag;
